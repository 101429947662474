import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },

  {
    path: '/studentDetail/:token/:number/:name', //首dmun
    name: 'studentDetail',
    component: () => import('@/views/main/student/detail.vue'),
    meta: {
      title: '学生基础信息'
    }
  },
  {
    path: '/cameraFace/:token/:number/:name', //$route.params.name
    name: 'cameraFace',
    component: () => import('@/views/main/student/cameraFace.vue'),
    meta: {
      title: '人脸照片录入'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/not-found/not-found.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue'),
    meta: {
      title: 'test'
    }
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

// 导航守卫
// router.beforeEach((to) => {
//   if (to.path !== '/login') {
//     const token = localCache.getCache('token')
//     if (!token) {
//       return '/login'
//     }
//   }

// console.log(router.getRoutes())
// console.log(to) // route对象

//   if (to.path === '/main') {
//     const token = localCache.getCache('token')
//     if (!token) {
//       return '/login'
//     }
//     return ''
//   }
// })

export default router
