import qs from 'qs'
import hyRequest from '../index'
import { IDataType } from '../types'

enum StudentAPI {
  UPLOAD_AVATAR = '/api/student/upload_avatar',
  GET_SCHOOL_INFO = '/api/student/school_info'
}

export function uploadAvatarRequest(data: any) {
  return hyRequest.post<IDataType>({
    url: StudentAPI.UPLOAD_AVATAR,
    data: qs.stringify(data)
  })
}
export function getSchoolInfo(data: any) {
  return hyRequest.get<IDataType>({
    url: StudentAPI.GET_SCHOOL_INFO,
    params: data
  })
}
