import { Module } from 'vuex'

import { accountLoginRequest } from '@/service/login/login'
import localCache from '@/utils/cache'
import router from '@/router'

import { IAccount } from '@/service/login/type'
import { ILoginState } from './types'
import { IRootState } from '../types'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {}
  },
  getters: {},
  mutations: {},
  actions: {
    async accountLoginAction({ commit, dispatch }, payload: IAccount) {
      const result = await accountLoginRequest(payload)
      return result
    }
  }
}

export default loginModule
