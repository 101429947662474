// service统一出口
import HYRequest from './request'

import localCache from '@/utils/cache'

const hyRequest = new HYRequest({
  baseURL: window.appConfig.httpUrl,
  timeout: 10000,
  // baseURL:BASE_URL,
  // timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      config.headers['content-Type'] = 'application/x-www-form-urlencoded'
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export default hyRequest
