import { Module } from 'vuex'

import { IRootState } from '../../types'

import { uploadAvatarRequest, getSchoolInfo } from '@/service/main/student'

const studentModule: Module<IRootState, IRootState> = {
  namespaced: true,
  state() {
    return {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getSchoolInfo({ commit, dispatch }, payload: any) {
      const result = await getSchoolInfo(payload)
      return result
    },

    async uploadStudentAvatarRequest({ commit }, data: any[]) {
      const result = await uploadAvatarRequest(data)
      return result
    }
  }
}

export default studentModule
